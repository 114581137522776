<template>
  <div id="header">
    <Menu
      theme="light"
      mode="horizontal"
      @on-open-change="closeDropdown"
      @on-select="handleRoute"
      :active-name="activeMenu"
      class="oj-menu"
      width="auto"
    >
      <Menu-item name="/" class="logo">
        <img src="../../../assets/miracom_logo.svg" class="logoImage" />
      </Menu-item>
      <Submenu
        class="problem-menu"
        name="/category-list"
        ref="problemList"
        v-if="isSuperAdmin || isLoggedIn()"
      >
        <template slot="title">
          <span @mouseenter="setProblemStepList" @click="isOpen('problemList')">
            <Icon type="ios-list-outline" style="margin-left: -2px"></Icon>
            <span class="menu-title">{{ $t("m.Category") }}</span>
          </span>
        </template>
        <template v-if="problemStepList.length">
          <template v-for="step in problemStepList">
            <!-- 라우터 지정하기 
          /problem_step/번호/level
          -->
            <!-- step의 id -->
            <Menu-item
              :key="step.id"
              :name="`/problem-step/${step.id}/level`"
              to="`/problem-step/1/level`"
            >
              <template>
                {{ step.title }}
              </template>
            </Menu-item>
          </template>

          <Menu-item name="/Favorite"> 즐겨찾기 </Menu-item>
        </template>
      </Submenu>
      <Menu-item
        name="/status"
        v-if="isSuperAdmin"
        class="status"
      >
        <span class="menu-title">채점결과</span>
      </Menu-item>
      <Submenu name="contests" v-if="isSuperAdmin" ref="contests">
        <template slot="title">
          <span @mouseenter="getAdminLists" @click="getAdminLists">
            <Icon type="person-stalker"></Icon>
            <span class="menu-title">시험응시</span>
          </span>
          <!-- <span class="menu-title" @click="getAdminLists">시험응시Test</span> -->
        </template>
        <Menu-item
        :style="qna.is_contest_visible ? '' : 'display:none;' "
          v-for="qna in contestList"
          :key="qna.id"
          :name="`/enterprise/contest/${makeRoute(qna.router_name)}`"
        >
          <span class="qna board">{{ qna.group }} 전용</span>
        </Menu-item>
      </Submenu>

      <Menu-item
        v-else-if="isValidContest()"
        :name="`/enterprise/contest/${makeRoute(
          $store.state.user.profile.mincoding_auth.router_name
        )}`"
      >
        <span>
          <Icon type="person-stalker"></Icon>
          <!-- <span class="menu-title" >시험응시Test</span> -->
          <span class="qna board" @click="getAdminLists">시험응시</span>
        </span>
      </Menu-item>
     

      <Submenu name="qna" v-if="isSuperAdmin" ref="qna">
        <template slot="title">
          <span
            @mouseenter="setProblemStepList"
            class="menu-title"
            @click="getAdminLists"
            >질문 게시판</span
          >
        </template>
        <Menu-item
        :style="qna.is_qna_visible ? '' : 'display:none;' "
          v-for="qna in qnaList"
          :key="qna.id"
          :name="`/qna/${makeRoute(qna.router_name)}`"
        >
          <span class="qna board">{{ qna.group }} 전용</span>
          <el-tag type="warning" v-if="qna.board_process_count != 0">{{
            qna.board_process_count
          }}</el-tag>
        </Menu-item>
        <!-- <Menu-item name="/qna/professor">
          <span class="qna board">강사 전용</span>
        </Menu-item>
        <Menu-item name="/qna/request">
          <span class="qna board">버그/요청 전용</span>
        </Menu-item> -->
      </Submenu>
      <!-- 본인 user profile에서 가져온거 바로 넣기 profile의 mincoding_auth에 있는 group가져오기    -->
   
      <!-- <Menu-item v-else-if="isValidQna()" :name="`/qna/${router_name}`"> -->
        <Menu-item
        v-else-if="isValidQna()"
        :name="`/qna/${makeRoute(
          $store.state.user.profile.mincoding_auth.router_name
        )}`"
      >
        <span
          @mouseenter="setProblemStepList"
          class="qna board"
          @click="getAdminLists"
          >질문 게시판</span
        >
      </Menu-item>

      <Submenu name="notice-list" v-if="isSuperAdmin" ref="notice-list">
        <template slot="title">
          <span class="menu-title board" @click="getAdminLists">응시가이드</span>
        </template>
        <Menu-item
          v-for="notice in noticeList"
          :key="notice.id"
          :name="`/announcement/${makeRoute(notice.router_name)}`"
        >
          <span class="notice-list board">{{ notice.group }} 전용</span>
        </Menu-item>
        <!-- <Menu-item name="/announcement/mincoding">
          <span class="notice-list board">민코딩 전용</span>
        </Menu-item> -->
      </Submenu>
      <!-- 본인 user profile에서 가져온거 바로 넣기 profile의 mincoding_auth에 있는 group가져오기    -->
      <!-- <Menu-item
        v-else-if="isValidAnnounce()"
        :name="`/announcement/${router_name}`"
      > -->
      <Menu-item
        v-else-if="isValidAnnounce()"
        :name="`/announcement/${makeRoute(
          $store.state.user.profile.mincoding_auth.router_name
        )}`"
      >
        <span class="notice board" @click="getAdminLists">응시가이드</span>
      </Menu-item>

      <!-- 23/02/01 변성은 추가 start -->
      <Submenu
        name="e_learnings"
        v-if="isSuperAdmin || isValidELearning()"
        ref="e_learnings"
        :class="{
          'ivu-menu-item-active ivu-menu-child-item-active': isActiveSubMenu(
            '/e_learnings',
            activeSubMenu
          ),
        }"
      >
        <template slot="title">
          <span class="e_learnings board" @click="getAdminLists"
            >e-learning</span
          >
        </template>
        <Menu-item name="/e_learnings/level/miracom">
          <span class="e_learnings-level board">Level별 강의</span>
        </Menu-item>
        <Menu-item name="/e_learnings/pro-new/miracom">
          <span class="e_learnings-pro board">Pro 원정대(신 유형)</span>
        </Menu-item>
          <Menu-item name="/e_learnings/pro/miracom">
          <span class="e_learnings-pro board">Pro 원정대(기존유형)</span>
        </Menu-item>
        <Menu-item name="/e_learnings/pro-learning/miracom">
          <span class="e_learnings-pro board">미라콤 러닝 트랙</span>
        </Menu-item>
      </Submenu>
      <!-- 23/01/30 변성은 추가 end -->

      <Menu-item
        name="/test-review-board-miracom"
        v-if="isSuperAdmin"
      >
        <span class="menu-title">시험후기</span>
      </Menu-item>

      <!-- <Menu-item name="/notice-miracom" v-if="isSuperAdmin || isValidMiracom || isValidSDS">
        <span class="menu-title">{{ $t("m.Announcements") }}</span>
      </Menu-item> -->

      <Submenu name="about" class="about" ref="about">
        <template slot="title">
          <span class="menu-title" @click="isOpen('about')">{{
            $t("m.About")
          }}</span>
        </template>
        <Menu-item name="/about">
          {{ $t("m.Judger") }}
        </Menu-item>
        <Menu-item name="/FAQ">
          {{ $t("m.FAQ") }}
        </Menu-item>
        <Menu-item name="/Java"> Java 입출력방법 </Menu-item>
      </Submenu>
    

      <template v-if="!isAuthenticated">
        <div class="btn-menu">
          <Button
            type="ghost"
            ref="loginBtn"
            shape="circle"
            @click="handleBtnClick('login')"
            >{{ $t("m.Login") }}
          </Button>
          <Button
            v-if="website.allow_register"
            type="ghost"
            shape="circle"
            @click="handleBtnClick('register')"
            style="margin-left: 5px"
            >{{ $t("m.Register") }}
          </Button>
        </div>
      </template>
      <template v-else>
        <Dropdown
          class="drop-menu"
          @on-click="handleRoute"
          placement="bottom"
          trigger="custom"
          :visible="currentVisible"
          @on-clickoutside="isOutclick"
        >
          <Button type="text" class="drop-menu-title" @click="isVisible"
            >{{ user.username }}님
            <Icon type="arrow-down-b"></Icon>
          </Button>
          <Dropdown-menu slot="list">
            <Dropdown-item name="/user-home">{{
              $t("m.MyHome")
            }}</Dropdown-item>
            <!-- <Dropdown-item
              name="/status?myself=1"
              v-if="isAdminRole || isSuperAdmin"
              >{{ $t("m.MySubmissions") }}</Dropdown-item
            > -->
            <!-- <Dropdown-item name="/problem" v-if="isSuperAdmin">{{
              $t("m.NavProblems")
            }}</Dropdown-item> -->
            <Dropdown-item name="/typing">{{ $t("m.Typing") }}</Dropdown-item>
            <Dropdown-item name="/setting/profile">{{
              $t("m.Settings")
            }}</Dropdown-item>
            <Dropdown-item v-if="isAdminRole" name="/admin">{{
              $t("m.Management")
            }}</Dropdown-item>
            <Dropdown-item divided name="/logout">{{
              $t("m.Logout")
            }}</Dropdown-item>
          </Dropdown-menu>
        </Dropdown>
      </template>
    </Menu>
    <Modal v-model="modalVisible" :width="400">
      <div slot="header" class="modal-title">
        {{ $t("m.Welcome_to") }} {{ website.website_name_shortcut }}
      </div>
      <component :is="modalStatus.mode" v-if="modalVisible"></component>
      <div slot="footer" style="display: none"></div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import login from "@oj/views/user/Login";
import register from "@oj/views/user/Register";
import api from "../api";
import adminApi from "../../admin/api";
import mixins from "./mixins/navBarMixins";

export default {
  components: {
    login,
    register,
  },
  mixins: [mixins],
};
</script>

<style lang="less" scoped>
#header {
  min-width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 1000;
  background-color: #fff;

  .oj-menu {
    background: #fdfdfd;
    font-family: "Noto Sans KR", sans-serif;
    .menu-title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
      margin-left: -7px;
    }
    .logo {
      margin-left: 5vw;
      padding-top: 1vh;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 3vw;
      .logoImage {
        width: 80px;
        height: 30px;
        font-size: 16px;
      }
    }
    .problem-menu {
      z-index: 10;
    }
    .board {
      font-size: 16px;
      font-weight: 500;
    }
    .drop-menu {
      float: right;
      margin-right: 50px;
      &-title {
        font-size: 18px;
      }
    }
    .btn-menu {
      font-size: 16px;
      margin-right: 5vw;
      float: right;
    }
  }
}

.modal {
  &-title {
    font-size: 18px;
    font-weight: 600;
  }
}

@media screen and (max-width: 900px) {
  #header {
    .drop-menu {
      float: none;
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 770px) {
  #header {
    .oj-menu {
      .logo {
        .logoImage {
          width: 70px;
          height: 30px;
          font-size: 16px;
        }
      }
    }
    .drop-menu {
      float: none;
      margin-right: 20px;
    }
  }
}

.online-button:hover {
  color: #2d8cfe;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .btn-menu {
    display: flex;
  }
}
</style>
