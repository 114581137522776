<template>
  <div>
    <el-input
      v-model="keyword"
      placeholder="키워드를 입력하세요"
      prefix-icon="el-icon-search"
    >
    </el-input>
    <el-table
      empty-text="검색한 정보가 없습니다"
      :data="problems"
      v-loading="loading"
    >
      <el-table-column
        v-if="!$store.state.isMobile"
        label="ID"
        width="100"
        prop="id"
      >
      </el-table-column>

      <el-table-column min-width="150" label="제목" prop="title">
      </el-table-column>
      <el-table-column label="옵션" align="center" width="100">
        <template slot-scope="{ row }">
          <icon-btn
            icon="plus"
            name="문제 추가하기"
            @click.native="handleAddContest(row)"
          ></icon-btn>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="page"
      layout="prev, pager, next"
      @current-change="getPublicContest"
      :page-size="limit"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      :class="$store.state.isMobile ? 'mobile-dialog' : ''"
      :width="$store.state.isMobile ? '100vw' : '70%'"
      top="50px"
      title="시험응시 복사하기"
      :visible.sync="showAddContestDialog"
      @open="onOpenEditDialog"
      
    >
      <el-form label-position="top">
        <el-form-item :label="'제목'" for="제목" required>
          <el-input v-model="title" placeholder="제목" class="title-input">
          </el-input>
        </el-form-item>

        <el-form-item label="카테고리(비밀코드 선택)" required>
          <el-select
            v-model="secret_code"
            placeholder="시험에 맞는 비밀코드 선택"
          >
            <el-option
              v-for="item in secret_code_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-input id="title" v-model="contest.title" placeholder="비밀코드를 선택해주세요"></el-input> -->
        </el-form-item>

        <!-- <el-col  :xs="24" :sm="24" :md="24" :lg="24"  > -->
        <el-form-item
          :label="'시작 시간'"
          class="font-weight"
          required
          for="startTime"
        >
          <el-date-picker
            id="startTime"
            v-model="start_time"
            type="date"
            format="yy년 M월d일 H시 m분"
            placeholder="시작시간"
          >
          </el-date-picker>
        </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col  :xs="24" :sm="24" :md="12" :lg="12"  > -->
        <el-form-item
          label="종료 시간(한번 더 확인해주세요.)"
          class="font-weight"
          required
          for="endTime"
        >
          <el-date-picker
            id="endTime"
            v-model="end_time"
            type="date"
            format="yy년 M월d일 H시 m분"
            placeholder="종료 시간"
            
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="비밀번호" class="font-weight" for="password">
          <el-input
            id="password"
            v-model="password"
            :placeholder="'비밀번호'"
          ></el-input>
        </el-form-item>
        <!-- </el-col> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <cancel @click.native="showAddContestDialog = false"></cancel>
        <save type="primary" @click.native="copyContest"></save>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "@admin/api";

export default {
  name: "add-problem-from-public",
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      problems: [],
      contest: {},
      keyword: "",
      title: "",
      start_time: null,
      end_time: null,
      showAddContestDialog: false,
      contest_id: null,
      secret_code: null,
      secret_code_list: [],
      password: null,
    };
  },
  mounted() {
    // const data  = api.getContestList().then(li => {
    //   console.log(li.data.data.results)
    //   this.problems =   li.data.data.results;
    // })
    this.getPublicContest();

    // api.getContest(this.contestID).then(res => {
    //   this.contest = res.data.data
    //   this.getPublicProblem()
    // }).catch(() => {
    // })
  },
  methods: {
    getPublicContest(page) {
      this.loading = true;
      let params = {
        keyword: this.keyword,
        offset: (page - 1) * this.limit,
        limit: this.limit,
      };
      api
        .getContestList(params.offset, params.limit, params.keyword)
        .then((res) => {
          this.loading = false;
          this.total = res.data.data.total;
          this.problems = res.data.data.results;
        });
    },
    handleAddContest(row) {
      // 이제 여기 선택하면 아이디 들어가서 보고
      // 그 아이디에
      // start_id
      // console.log(row);
      this.showAddContestDialog = true;
      this.title = row.title;
      this.contest_id = row.id;
      this.secret_code = row.secret_code;

      // const {data} = await api.getSecretCodeAuth();
      // this.secret_code_list = data.data;
      api.getSecretCodeAuth().then((li) => {
        this.secret_code_list = li.data.data;
      });

      // this.$prompt('시험응시 문제에 대한 표시 ID를 입력하십시오', '확인').then(({value}) => {
      //   let data = {
      //     contest_id: ContestID,
      //     title: this.title,
      //     start_time: this.start_time,
      //     end_time: this.end_time,

      //   }
      //   api.copyContest(data).then(() => {
      //     this.$emit('on-change')
      //   }, () => {
      //   })
      // }, () => {
      // })
    },
    onOpenEditDialog() {
      // 할일 최적화
      // 일시적으로 텍스트 편집기 디스플레이 비정상 버그 해결g
      setTimeout(() => {
        if (document.createEvent) {
          let event = document.createEvent("HTMLEvents");
          event.initEvent("resize", true, true);
          window.dispatchEvent(event);
        } else if (document.createEventObject) {
          window.fireEvent("onresize");
        }
      }, 0);
    },

    changeEndTime() {
      this.end_time.setMinutes(this.end_time.getMinutes() + 1);
    },
    copyContest() {
      let data = {
        contest_id: this.contest_id,
        title: this.title,
        start_time: this.start_time,
        end_time: this.end_time,
        secret_code: this.secret_code,
        password: this.password,
      };
      api.copyContest(data).then(() => {
        this.$emit("on-change");
        this.title = null;
        this.start_time = null;
        this.end_time = null;
        this.password = null;
        this.secret_code = null;
      });
      this.showAddContestDialog = false;
    },
  },
  watch: {
    keyword() {
      this.getPublicContest(this.page);
    },
  },
};
</script>
<style scoped>
.page {
  margin-top: 20px;
  text-align: right;
}
</style>
<style>
.v-modal {
  z-index: 999 !important;
}
</style>
