<template>
  <div class="view">
    <AdminPanel
      :title="contestId ? '시험 문항 목록' : '문제 목록'"
      :style="$route.path === '/problem' ? 'padding: 0 10%' : ''"
    >
      <div
        slot="header"
        style="display: flex; align-items: center"
        :style="$store.state.isMobile ? 'margin-top:60px;' : ''"
      >
        <el-input
          :size="$store.state.isMobile ? 'mini' : ''"
          @input="onChangeKeyword"
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="Keywords"
        >
        </el-input>
        
        <el-input
          v-if="!isTagList &&!$route.params.contestId"
          :size="$store.state.isMobile ? 'mini' : ''"
          style="margin-left: 10px"
          v-model="tag"
          @input="onChangeTag"
          prefix-icon="el-icon-search"
          placeholder="Tags"
        >
        </el-input>
        
        <div style="display: flex" v-if="$store.state.problem.tagList.length" >
          <el-tag
            closable
            @click.native="handleClose(tag)"
            @close="handleClose(tag)"
            v-for="tag in $store.state.problem.tagList"
            :key="tag.id"
            style="margin-left: 5px"
          >
            {{ tag.name }}
          </el-tag>
        </div>

        <el-switch
          v-if="!$route.params.contestId"
          class="tag-el-switch"
          active-text="태그 상세 검색"
          @change="onChangeSwitch"
          v-model="isTagList"
        >
        </el-switch>

        <el-button
          style="margin-left: 8px"
          v-if="isTagList && !$route.params.contestId"
          @click="
            () => {
              tagModal = true;
            }
          "
        >
          상세검색 태그 추가
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="loading"
        ref="table"
        :data="problemList"
        @row-dblclick="handleDblclick"
        style="width: 100%"
      >
        <el-table-column
          v-if="!$store.state.isMobile && $route.path !== '/problem'"
          width="100"
          prop="id"
          label="ID"
        >
        </el-table-column>
        <el-table-column width="180" label="Display ID">
          <template slot-scope="{ row }">
            <span v-show="!row.isEditing">{{ row._id }}</span>
            <el-input
              v-show="row.isEditing"
              v-model="row._id"
              @keyup.enter.native="handleInlineEdit(row)"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="200" prop="title" label="제목">
          <template slot-scope="{ row }">
            <span v-show="!row.isEditing">{{ row.title }}</span>
            <el-input
              v-show="row.isEditing"
              v-model="row.title"
              @keyup.enter.native="handleInlineEdit(row)"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column min-width="140" prop="tags" label="태그">
          <template slot-scope="{ row }">
            <el-tag
              class="el-tags"
              v-for="tag of row.tags"
              :key="tag.id"
              style="margin: 1.5px"
              type="mini"
              @click="searchTag(tag)"
            >
              {{ tag.name }}
            </el-tag>
            <!-- <span v-show="!row.isEditing">{{ row.tags }}</span> -->

            <!-- </el-input> -->
          </template>
        </el-table-column>

        <el-table-column
          v-if="!$store.state.isMobile && $route.path !== '/problem'"
          width="180"
          prop="created_by.username"
          label="작성자"
        >
        </el-table-column>
        <el-table-column
          v-if="!$store.state.isMobile && $route.path !== '/problem'"
          width="180"
          prop="create_time"
          label="생성 날짜"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | localtime("yyyy-M-DD hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="$route.path !== '/problem'"
          min-width="120"
          align="center"
          prop="visible"
          label="문제 공개"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.visible"
              active-text=""
              inactive-text=""
              @change="updateProblem(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column
          width="140"
          prop="teacher_code_visible"
          align="center"
          label="강사님 코드 공개">
          <template slot-scope="scope">
            <el-switch v-model="scope.row['teacher_code_visible']"
                       active-text=""
                       inactive-text=""
                       @change="updateTeacherCodeVisible(scope.row)">
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column
          label="옵션"
          :min-width="$route.path === '/problem' ? '160' : 250"
        >
          <div slot-scope="scope">
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="goEdit(scope.row.id)"
            ></icon-btn>
            <!-- <icon-btn
              v-if="contestId"
              name="공개하기"
              icon="copy-document"
              @click.native="makeContestProblemPublic(scope.row.id)"
            ></icon-btn> -->
            <icon-btn
              v-if="!$store.state.isMobile"
              icon="download"
              name="테스트케이스 다운로드"
              @click.native="downloadTestCase(scope.row.id)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="문제 삭제"
              @click.native="deleteProblem(scope.row.id)"
            ></icon-btn>
            <icon-btn
              icon="link"
              name="해당 페이지로 이동"
              @click.native="goHompage(scope.row)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
      <div class="panel-options">
        <el-button
          type="primary"
          :class="$store.state.isMobile ? 'problem-add' : ''"
          :size="$store.state.isMobile ? 'mini' : 'small'"
          @click="goCreateProblem"
          icon="el-icon-plus"
          >생성
        </el-button>
        <el-button
          v-if="contestId"
          type="primary"
          :class="$store.state.isMobile ? 'open-problem-add' : ''"
          :size="$store.state.isMobile ? 'mini' : 'small'"
          icon="el-icon-plus"
          @click="addProblemDialogVisible = true"
          >공개 문제 추가
        </el-button>
        <el-pagination
          class="page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </AdminPanel>
    <el-dialog
      title="문제를 업데이트 하시겠습니까?"
      width="20%"
      :visible.sync="InlineEditDialogVisible"
      @close-on-click-modal="false"
    >
      <div>
        <p>DisplayID: {{ currentRow._id }}</p>
        <p>Title: {{ currentRow.title }}</p>
      </div>
      <span slot="footer">
        <cancel
          @click.native="
            InlineEditDialogVisible = false;
            getProblemList(currentPage);
          "
        ></cancel>
        <save @click.native="updateProblem(currentRow)"></save>
      </span>
    </el-dialog>

    <el-dialog
      title="공개 문제 추가"
      v-if="contestId"
      top="50px"
      :width="$store.state.isMobile ? '100vw' : '50%'"
      :visible.sync="addProblemDialogVisible"
      :before-close="handleCloseProblemDialog"
    >
      <add-problem-component
        :contestID="contestId"
        @on-change="getProblemList"
      ></add-problem-component>
    </el-dialog>

    <el-dialog
      title="태그 검색하기"
      top="50px"
      :width="$store.state.isMobile ? '100vw' : '50%'"
      :visible.sync="tagModal"
    >
      <AddTag type="search" @saveTag="tagModal = false"></AddTag>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api.js";
import utils from "@/utils/utils";
import AddProblemComponent from "./AddPublicProblem.vue";
import AdminPanel from "@admin/components/AdminPanel.vue";
import AddTag from "@admin/views/problem/AddTag.vue";
import { types } from "@/store";
import _ from "lodash";
export default {
  name: "ProblemList",
  components: {
    AddProblemComponent,
    AdminPanel,
    AddTag,
  },
  data() {
    return {
      pageSize: 12,
      total: 0,
      problemList: [],
      keyword: "",
      tag: "",

      loading: false,
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      routeName: "",
      contestId: "",
      // for make public use
      currentProblemID: "",
      currentRow: {},
      InlineEditDialogVisible: false,
      makePublicDialogVisible: false,
      addProblemDialogVisible: false,
      isTagList: false,
      tagModal: false,
      options: [
        {
          value: "name",
          label: "제목 검색",
        },
        {
          value: "displayID",
          label: "displayID 검색",
        },
      ],
      searchType: "name",
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    this.contestId = this.$route.params.contestId;

    const { keyword, tag } = this.$route.query;
    if (keyword) {
      this.keyword = keyword;
    }

    if (tag) {
      this.tag = tag;
    }

    this.getProblemList(this.currentPage);
  },
  methods: {
    handleDblclick(row) {
      row.isEditing = true;
    },
    goEdit(problemId) {
      if (this.routeName === "problem-list") {
        this.$router.push({ name: "edit-problem", params: { problemId } });
      } else if (this.routeName === "admin-contest-problem-list") {
        this.$router.push({
          name: "edit-contest-problem",
          params: { problemId: problemId, contestId: this.contestId },
        });
      }
    },
    goCreateProblem() {
      if (this.routeName === "problem-list") {
        this.$router.push({ name: "create-problem" });
      } else if (this.routeName === "admin-contest-problem-list") {
        this.$router.push({
          name: "create-contest-problem",
          params: { contestId: this.contestId },
        });
      }
    },
    currentChange(page) {
      this.currentPage = page;
      this.getProblemList(page);
      this.$router.push({
        query: { page: this.currentPage, keyword: this.keyword, tag: this.tag },
      });
    },
    getProblemList(page = 1) {
      this.loading = true;
      let funcName =
        this.routeName === "problem-list"
          ? "getProblemList"
          : "getContestProblemList";

      // 태그 분기하기 채점 테스트

      let tag = "";

      let type = null;

      if (this.$store.state.problem.tagList.length) {
        tag = this.$store.state.problem.tagList.map((li) => li.name).join(",");
        type = "list";
      } else if (this.tag.split(",").length > 1) {
        tag = this.tag.split(",");
      } else {
        tag = this.tag;
        this.isTagList = false;
        type = "string";
      }

      let params = {
        limit: this.pageSize,
        offset: (page - 1) * this.pageSize,
        keyword: this.keyword,
        contest_id: this.contestId,
        tag: tag,
        type: type,
      };

      api[funcName](params).then(
        (res) => {
          this.loading = false;
          this.total = res.data.data.total;
          for (let problem of res.data.data.results) {
            problem.isEditing = false;
          }
          this.problemList = res.data.data.results;
        },
        (res) => {
          this.loading = false;
        }
      );
    },
    deleteProblem(id) {
      this.$confirm(
        "문제를 삭제 하시겠습니까? 관련 제출물도 삭제됩니다.",
        "문제 삭제",
        {
          type: "warning",
        }
      ).then(
        () => {
          let funcName =
            this.routeName === "problem-list"
              ? "deleteProblem"
              : "deleteContestProblem";
          api[funcName](id)
            .then(() => [this.getProblemList(this.currentPage - 1)])
            .catch(() => {});
        },
        () => {}
      );
    },
    makeContestProblemPublic(problemID) {
      this.$prompt("공개 문제에 대한 표시 ID를 입력하십시오", "확인").then(
        ({ value }) => {
          api
            .makeContestProblemPublic({ id: problemID, display_id: value })
            .catch();
        },
        () => {}
      );
    },
    updateProblem(row) {
      let data = Object.assign({}, row);
      let funcName = "";
      if (this.contestId) {
        data.contest_id = this.contestId;
        funcName = "editContestProblem";
      } else {
        funcName = "editProblem";
      }
      api[funcName](data)
        .then((res) => {
          this.InlineEditDialogVisible = false;
          this.getProblemList(this.currentPage);
        })
        .catch(() => {
          this.InlineEditDialogVisible = false;
        });
    },
    // updateTeacherCodeVisible (row) {
    //   let data = Object.assign({}, row)
    //   let funcName = ''
    //   if (this.contestId) {
    //     data.contest_id = this.contestId
    //     funcName = 'editContestProblem'
    //   } else {
    //     funcName = 'editProblem'
    //   }
    //   api[funcName](data).then(res => {
    //     this.InlineEditDialogVisible = false
    //     this.getProblemList(this.currentPage)
    //   }).catch(() => {
    //     this.InlineEditDialogVisible = false
    //   })
    // },
    handleInlineEdit(row) {
      this.currentRow = row;
      this.InlineEditDialogVisible = true;
    },
    downloadTestCase(problemID) {
      let url = "/admin/test_case?problem_id=" + problemID;
      utils.downloadFile(url);
    },
    getPublicProblem() {
      api.getProblemList();
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getProblemList(this.currentPage);
    },
    goHompage(row) {
      const { contestId } = this.$route.params;
      if (contestId) {
        window.open(
          `${window.location.origin}/enterprise/contest/miracom/${contestId}/problem/${row._id}`
        );
      } else {
        window.open(`${window.location.origin}/problem/${row._id}`);
      }
    },
    searchTag(tag) {
      this.isTagList = false;

      this.tag = tag.name;
      // this.$store.commit(types.CLEAR_TAG);
    },
    onChangeSwitch(e) {
      if (e === true) {
        this.tagModal = true;
      } else {
        //날리기
        this.tagModal = false;
        this.tag = "";
        this.$store.commit(types.CLEAR_TAG);
      }
    },
    handleClose(tag) {
      this.$store.commit(types.REMOVE_TAG, tag);
    },

    handleCloseProblemDialog(done) {

      this.$confirm("창을 닫으시겠습니까?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    onChangeKeyword: _.debounce(function () {
      this.currentChange();
    }, 400), 
    onChangeTag: _.debounce(function () {
      this.currentChange();
    }, 400), 


  },
  watch: {
    $route(newVal, oldVal) {
      this.keyword = newVal.query.keyword;
      this.tag = newVal.query.tag;

      this.contestId = newVal.params.contestId;
      this.routeName = newVal.name;
      this.getProblemList(this.currentPage);
    },
    searchType() {
      this.currentChange();
    },
    // keyword() {
    //   this.currentChange();
    // },
    // tag() {
    //   this.currentChange();
    // },
    "$route.query": function () {
      this.page();
    },
    "$store.state.problem.tagList": function () {
      if (this.$store.state.problem.tagList.length) {
        // console.log(this.$store.state.problem.tagList);
        // this.tag = this.$store.state.problem.tagList;
      } else {
        this.tag = "";
      }

      this.currentChange();
    },
  },
};
</script>

<style scoped lang="less">
.panel-options {
  position: relative;
}

.panel-options > button {
  position: absolute;
}
.problem-add {
  bottom: -55px;
}
.open-problem-add {
  bottom: -55px;
  left: 80px;
}
</style>

<style>
.el-tags {
  cursor: pointer;
}
.tag-el-switch {
  min-width: 150px;
  margin-left: 15px;
}
</style>
