import Sortable from "sortablejs";
import api from "../../api";
const mixins = {
  mounted() {
    if(!this.$store.state.isMobile){
      this.rowDrop();
    }
    // this.columnDrop();
  },
  methods: {
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        animation:150,
        delay:5,
        easing: "cubic-bezier(1, 0, 0, 1)",

        async onEnd({ newIndex, oldIndex }) {
         

          // route로 조절하자
          // route에 problem_step_id 와 problem_step_level_id 가 존재하는지
          // problem_step_id만 존재하는지
          // 존재 안하는지
          const {
            problem_step_id,
            problem_step_level_id
          } = _this.$route.params;

          // console.log(problem_step_id)
          // console.log(problem_step_level_id)

          if (problem_step_id && problem_step_level_id) {
            const currRow = _this.problemList['problem_step_level'].splice(oldIndex, 1)[0];
            _this.problemList['problem_step_level'].splice(newIndex, 0, currRow);
            // console.log(currRow)
            
            await Promise.all(
              _this.problemList['problem_step_level'].map((li, index) => {
                api.changeOrder({ problem_step_level_detail_id: li.id }, index);
              })
            ).then(_this.getProblemStepLevelDetailList());
          } else if (problem_step_id) {
            const currRow = _this.problemList['problem_step'].splice(oldIndex, 1)[0];
            _this.problemList['problem_step'].splice(newIndex, 0, currRow);
            await Promise.all(
              _this.problemList['problem_step'].map((li, index) => {
                api.changeOrder({ problem_step_level_id: li.id }, index);
              })
            ).then(_this.getProblemStepLevelList());
          } else {
            const currRow = _this.problemList.splice(oldIndex, 1)[0];
            _this.problemList.splice(newIndex, 0, currRow);
            await Promise.all(
              _this.problemList.map((li, index) => {
                api.changeOrder({ problem_step_id: li.id }, index);
              })
            ).then(_this.getProblemStepList());
          }
        }
      });
    }
  }
};

export default mixins;
