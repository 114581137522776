<template>
  <div class="view">
    <AdminPanel title="권한">
      <div slot="header"></div>
      <el-table v-if="problemList" :data="problemList" style="width: 100%">
        <el-table-column width="400" prop="id" label="ID"> </el-table-column>
        <el-table-column width="500" prop="name" label="권한 이름">
        </el-table-column>
        <el-table-column  label="옵션" width="300">
          <div slot-scope="scope">
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="goEdit(scope.row.id)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="문제 삭제"
              @click.native="deleteProblem(scope.row.id)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
    </AdminPanel>
  </div>
</template>

<script>
import api from "../../../api";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "auth_list",
  components: {AdminPanel},
  data() {
    return {
      pageSize: 10,
      total: 0,
      problemList: [],
      keyword: "",
      loading: false
    };
  },
  async mounted() {
    const result = await this.getList();
    // console.log(result);
    this.problemList = result.data.data;
  },
  methods: {
    goCreate() {
      this.$router.push("/mincoding_auth/create");
    },
    getList() {
      return api.getMincodingAuthList();
    },
    goEdit(id){
      this.$router.push(`/mincoding_auth/edit/${id}`);
    },
    async deleteProblem(id){
      await api.deleteMincodingAuth(id)
      const result = await this.getList();
      this.problemList = result.data.data;
    }
  }
};
</script>

<style scoped lang="less"></style>
