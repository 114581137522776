<template>
  <Row>
    <Panel shadow>
      <!--aa
      <div v-if="problemStepLevelList" slot="title">{{problemStepLevelList.title}}</div>
      -->

      <div v-if="problemStepLevelList" slot="title">
        {{ problemStepLevelList.content }}
      </div>

      <div v-if="problemStepLevelList" slot="title">
        {{ startTitle }} ~ {{ endTitle }}
      </div>

      <div style="padding-left: 29px" v-if="isAdminRole">
        <div>
          <a @click="stepEdit">스텝 수정하기</a>
        </div>
        <div>
          <a @click="levelEdit">레벨 리스트 추가하기</a>
        </div>
      </div>
      <div v-else slot="title">문항 목록</div>
      <BoardHighlight
        class="bg-code ck-content"
        :code="problemStepLevelList.content_detail"
      ></BoardHighlight>
      <!-- <ToastViewer v-if="problemStepLevelList.content_detail" :content="problemStepLevelList.content_detail">
          </ToastViewer> -->
      <!-- <div
        style="max-width: 800px; padding: 20px"
        v-html="problemStepLevelList.content_detail"
      ></div> -->
      <!-- <div v-html="problemStepLevelList.content_detail"></div> -->
      <!-- edit 버튼 추가 (2개, 설명수정, 리스트 수정) -->
      <!-- <div slot="title">Level별 리스트</div> -->
      <Table
        border
        @on-row-click="move"
        :columns="columns"
        :data="problemStepLevelList['problem_step']"
      >
      </Table>
    </Panel>
  </Row>
</template>
<script>
import store, { types } from "../../../../store";
import { mapMutations, mapState, mapGetters } from "vuex";
import api from "../../api";
import ToastViewerMixins from "@/pages/admin/components/toastViewerMixins.js";
import BoardHighlight from "../../components/BoardHighlight.vue";

export default {
  mixins: [ToastViewerMixins],
  data() {
    return {
      problemStepLevelList: [],
      columns: [],
      startTitle: "",
      endTitle: "",
    };
  },
  async mounted() {
    if (!this.isLoggedIn()) {
      this.$router.push("/");
      this.$error("권한이 없습니다.");
    }
    // -> 그 레벨이 맞는 problemStepLevel 리스트 호출
    if (this.isSuperAdmin) {
      this.changeTableView();
    }
    // 이제 여기서 list에 대한 정보 + 가져와야함
    // 표에 매핑
  },
  computed: {
    ...mapState(["problemTitle", "utils", "user"]),
    ...mapGetters(["isAdminRole", "isSuperAdmin"]),
  },
  created() {
    this.category = this[this.$route.query.type];
    this.changeTableView();
  },
  watch: {
    "$route.params.step_id"(to, from) {
      let isValidEmail = false;
      let isValidAuth = false;
      const profile = store.getters.profile;
      if (profile && profile.user.email && profile["mincoding_auth"]) {
        if (!profile["mincoding_auth"]["verify_email"]) {
          isValidEmail = true;
        }
        if (profile["mincoding_auth"]["verify_email"]) {
          if (
            profile.user.email.indexOf(
              profile["mincoding_auth"]["verify_email"]
            ) > -1
          ) {
            isValidEmail = true;
          }
        }
        if (
          profile.user.email.indexOf("@zigbang.com") > -1 &&
          profile.github.toLowerCase() === "sds"
        ) {
          isValidEmail = true;
        }
      }
      // 그룹 권한 인증
      let mincodingAuth = [];
      if (
        profile["mincoding_auth"] &&
        profile["mincoding_auth"]["secret_code"].length
      ) {
        mincodingAuth = profile["mincoding_auth"]["secret_code"].map(
          (li) => li.problem_step.id
        );
      }
      // 그룹권한은 이메일 권한도 같이 있어야한다
      if (mincodingAuth.includes(Number(to)) && isValidEmail) {
        isValidAuth = true;
      }
      // 유저 권한 인증
      let userAuth = [];
      if (profile["user_auth"] && profile["user_auth"].length) {
        userAuth = profile["user_auth"].map((li) => li.problem_step.id);
      }
      if (userAuth.includes(Number(to))) {
        isValidAuth = true;
      }

      if (store.getters.isSuperAdmin || store.getters.isAdminRole) {
        this.category = this[this.$route.query.type];
        this.changeTableView();
      } else if (isValidAuth) {
        this.category = this[this.$route.query.type];
        this.changeTableView();
      } else {
        // this.$router.replace(`/problem-step/${from}/level`);
        window.location.href = window.location.origin;
        // console.log('a?');
        alert("권한이 존재하지 않습니다.");
      }
    },
  },
  methods: {
    ...mapMutations(["CHANGE_PROBLEM_TITLE"]),
    async changeTableView() {
      await api.getUserInfo().then((res) => {
        this.$store.commit(types.CHANGE_PROFILE, { profile: res.data.data });
      });
      const { step_id } = this.$route.params;
      const { data } = await api.getProblemStep(step_id);
      this.problemStepLevelList = data["data"]["data"];
      this.startTitle = this.problemStepLevelList.problem_step[0]
        ? this.problemStepLevelList.problem_step[0].title
        : "";
      this.endTitle = this.problemStepLevelList.problem_step[0]
        ? this.problemStepLevelList.problem_step[
            this.problemStepLevelList.problem_step.length - 1
          ].title
        : "";
      // console.log(this.problemStepLevelList);
      // console.log(this.$store.state)
      const acm =
        this.$store.state.user.profile["acm_problems_status"]["problems"];
      const oi =
        this.$store.state.user.profile["oi_problems_status"]["problems"];
      let acmList = null;
      let list = [];
      if (acm) {
        acmList = Object.values(acm)
          .filter((li) => li.status === 0)
          .map((li) => li._id);
        list = [...acmList];
      }
      let oiList = null;
      if (oi) {
        oiList =
          oi &&
          Object.values(oi)
            .filter((li) => li.status === 0)
            .map((li) => li._id);
        list = [...list, ...oiList];
      }
      if (list) {
        list = new Set(list);
        list = Array.from(list);
      }
      // const array1 = [1,2 ,8, 4, 5];
      // const array2 = [4, 5, 6, 7,8];
      // // 두가지를 비교해서 공통된거 가져오기
      // let count = 0;
      // for(let y=0; y< array1.length; y++){
      //   for(let x = 0; x< array2.length; x++){
      //     if(array1[y] === array2[x]){
      //       count ++;
      //     }
      //   }
      // }
      // console.log(count);
      this.problemStepLevelList &&
        this.problemStepLevelList.problem_step &&
        this.problemStepLevelList.problem_step.length &&
        this.problemStepLevelList.problem_step.reduce((acc, cur) => {
          // 여기서 acc.prblem_step_level 과 list를 비교하면된다
          // list에 있는문제 / 총 문제 갯수 = 퍼센트를 나타낸다.
          let count = 0;
          // 겹치는거 만들기
          // console.log(cur.problem_step_level);
          if (cur && cur.problem_step_level && cur.problem_step_level.length) {
            for (let y = 0; y < cur.problem_step_level.length; y++) {
              for (let x = 0; x < list.length; x++) {
                if (
                  cur.problem_step_level[y].visible &&
                  cur.problem_step_level[y].problem._id === list[x]
                ) {
                  count = count + 1;
                }
              }
            }
          }
          let percent = 0;
          if (cur.problem_step_level && cur.problem_step_level.length) {
            const levelLength = cur.problem_step_level.filter(
              (li) => li.visible
            );
            // console.log(levelLength)
            percent = ((count / levelLength.length) * 100).toFixed(0);
            if (count === 0 && levelLength.length === 0) {
              percent = 0;
            }
          }
          // if(percent > 100){
          //   percent = 100;
          // }
          cur.percent = percent;
          acc.push(cur);
          // 채점하고 나서 유저 정보 업데이트를 하는 로직이 필요하다.
          return acc;
        }, []);
      // console.log(this.problemStepLevelList);
      // 이제해야하는것 list와
      // checkedList.add()
      // 이제 가져와야하는것
      // this.$store.state에 있는 acm_problems_status 에있는 _id
      // oi_problems_status에 있는 _id를 가져와서 합친다음에 비교해야한다
      this.category = this[this.$route.query.type];
      this.columns = [
        {
          title: "Num",
          align: "center",
          width: this.$store.state.isMobile ? 60 : 150,
          render: (h, params) => {
            return h("span", params.index + 1);
          },
        },
        {
          title: "Level",
          align: "left",
          className: "iview-table-title",
          align: "center",
          width: this.$store.state.isMobile ? 150 : 250,
          render: (h, params) => {
            return h("span", {}, params.row.title);
          },
        },
        {
          title: "완성도",
          align: "center",
          width: this.$store.state.isMobile ? 60 : 600,
          render: (h, params) => {
            if (!this.$store.state.isMobile) {
              return h(
                "el-progress",
                {
                  props: {
                    "stroke-linecap": "square",
                    "text-inside": true,
                    percentage: Number(params.row.percent),
                    "stroke-width": 30,
                  },
                  style: {
                    padding: "12px 15px !important",
                    "text-align": "left !important",
                  },
                },
                params.row.percent
              );
            } else {
              return h("div", `${params.row.percent}%`);
            }
          },
        },
        {
          title: "내용",
          align: "center",
          width: this.$store.state.isMobile ? 150 : 300,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  "text-align": "left !important",
                },
              },
              params.row.content
            );
          },
        },
      ];
    },
    move(row, index) {
      this.CHANGE_PROBLEM_TITLE(row.title);
      // /problem-step/${this.$route.params}/level/${row.id}/detail
      // /problem-step/:step_id/level/:level_id/detail
      this.$router.push(
        `/problem-step/${this.$route.params.step_id}/level/${row.id}/detail`
      );
    },
    stepEdit() {
      const { step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}/edit`);
    },
    levelEdit() {
      const { step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}`);
    },
    // userAuth(){
    //   if(this.user.profile.user.admin_type === 'Regular User'){
    //     const auth = this.user.profile.auth
    //     if(auth.includes('all')){
    //       return true
    //     }
    //     if(auth.includes('시작반1') && this.$route.path.includes("problem-step/1")){
    //       return true
    //     }
    //     if(auth.includes('시작반2') && this.$route.path.includes("problem-step/2")){
    //       return true
    //     }
    //     if(auth.includes('훈련반1') && this.$route.path.includes("problem-step/3")){
    //       return true
    //     }
    //     if(auth.includes('훈련반2') && this.$route.path.includes("problem-step/4")){
    //       return true
    //     }
    //     if(auth.includes('심화반1') && this.$route.path.includes("problem-step/5")){
    //       return true
    //     }
    //     if(auth.includes('심화반2') && this.$route.path.includes("problem-step/6")){
    //       return true
    //     }
    //     if(auth.includes('algorithm') && this.$route.path.includes("problem-step/7")){
    //       return true
    //     }
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    isLoggedIn() {
      return !!(
        this.$store.state["user"]["profile"] &&
        this.$store.state["user"]["profile"].id
      );
    },
  },
  components: { BoardHighlight },
};
</script>

<style>
.el-progress-bar__inner {
  border-radius: 5px !important;
}
.el-progress-bar__outer {
  border-radius: 0px !important;
}
.ivu-table-header th {
  text-align: center !important;
}
.iview-table-title .ivu-table-cell {
  padding: 0;
}
</style>
