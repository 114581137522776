<template>
  <div>
    <Menu
      id="moblie-header"
      theme="light"
      mode="horizontal"
      v-click-outside="vcoConfig"
    >
      <div class="gnb" @click="showGnb">
        <i class="el-icon-s-unfold" v-if="mobileFlag"></i>
        <i class="el-icon-close" v-if="!mobileFlag"></i>
      </div>
      <img
        @click="moveMain"
        src="../../../assets/miracom_logo.svg"
        class="logoImage"
      />

      <template v-if="!isAuthenticated">
        <div class="btn-menu">
          <Button
            type="ghost"
            ref="loginBtn"
            shape="circle"
            @click="handleBtnClick('login')"
            >{{ $t("m.Login") }}
          </Button>
          <Button
            v-if="website.allow_register"
            type="ghost"
            shape="circle"
            @click="handleBtnClick('register')"
            style="margin-left: 5px"
            >{{ $t("m.Register") }}
          </Button>
        </div>
      </template>
      <template v-else>
        <Dropdown
          class="drop-menu"
          @on-click="handleRoute"
          placement="bottom"
          trigger="custom"
          :visible="currentVisible"
          @on-clickoutside="isOutclick"
        >
          <Button type="text" class="drop-menu-title" @click="isVisible"
            >{{ user.profile.user.username }}님
            <Icon type="arrow-down-b"></Icon>
          </Button>
          <Dropdown-menu slot="list">
            <Dropdown-item name="/user-home">{{
              $t("m.MyHome")
            }}</Dropdown-item>
            <!-- <Dropdown-item
              name="/status?myself=1"
              v-if="isAdminRole || isSuperAdmin"
              >{{ $t("m.MySubmissions") }}</Dropdown-item
            > -->
            <!-- <Dropdown-item name="/problem" v-if="isSuperAdmin">{{
              $t("m.NavProblems")
            }}</Dropdown-item> -->
            <Dropdown-item name="/typing">{{ $t("m.Typing") }}</Dropdown-item>
            <Dropdown-item name="/setting/profile">{{
              $t("m.Settings")
            }}</Dropdown-item>
            <Dropdown-item v-if="isAdminRole" name="/admin">{{
              $t("m.Management")
            }}</Dropdown-item>
            <Dropdown-item divided name="/logout">{{
              $t("m.Logout")
            }}</Dropdown-item>
          </Dropdown-menu>
        </Dropdown>
      </template>
    </Menu>
    <div id="header" ref="header">
      <Menu
        @on-select="handleRoute"
        :active-name="activeMenu"
        @on-open-change="closeDropdown"
        :accordion="true"
        class="oj-menu"
        width="auto"
      >
        <Menu-item name="/">
          <Icon type="home"></Icon>
          <span class="menu-title">{{ $t("m.Home") }}</span>
        </Menu-item>
        <Submenu name="/category-list" v-if="isSuperAdmin || isLoggedIn()">
          <template slot="title">
            <Icon type="ios-list-outline" style="margin-left: -2px"></Icon>
            <span class="menu-title">{{ $t("m.Category") }}</span>
          </template>
          <template v-if="problemStepList.length">
            <Menu-item
              v-for="step in problemStepList"
              :key="step.id"
              :name="`/problem-step/${step.id}/level`"
              replace="true"
            >
              {{ step.title }}
            </Menu-item>
          </template>
        </Submenu>

        <Menu-item
          name="/status"
          v-if="isSuperAdmin "
          class="status"
        >
          <span class="menu-title">{{ $t("m.NavStatus") }}</span>
        </Menu-item>

        <Submenu name="contests" v-if="isSuperAdmin" ref="contests">
          <template slot="title">
            <span @click="getAdminLists">
              <Icon type="person-stalker"></Icon>
              <span class="menu-title">시험응시</span>
            </span>
            <!-- <span class="menu-title" @click="getAdminLists">시험응시Test</span> -->
          </template>
          <Menu-item
          :style="qna.is_contest_visible ? '' : 'display:none;' "
            v-for="qna in contestList"
            :key="qna.id"
            :name="`/enterprise/contest/${makeRoute(qna.router_name)}`"
          >
            <span class="qna board">{{ qna.group }} 전용</span>
          </Menu-item>
        </Submenu>

        <Menu-item
          v-else-if="isValidContest()"
          :name="`/enterprise/contest/${makeRoute(
            $store.state.user.profile.mincoding_auth.router_name
          )}`"
        >
          <span>
            <Icon type="person-stalker"></Icon>
            <!-- <span class="menu-title" >시험응시Test</span> -->
            <span class="qna board" @click="getAdminLists">시험응시</span>
          </span>
        </Menu-item>


        <Submenu name="qna" v-if="isSuperAdmin" ref="qna">
          <template slot="title">
            <span class="menu-title" @click="getAdminLists">질문 게시판</span>
          </template>
          <Menu-item
            :style="qna.is_qna_visible ? '' : 'display:none;' "
            v-for="qna in qnaList"
            :key="qna.id"
            :name="`/qna/${makeRoute(qna.router_name)}`"
          >
            <span class="qna board">{{ qna.group }} 전용</span>
            <el-tag
              type="warning"
              size="mini"
              v-if="qna.board_process_count != 0"
              >{{ qna.board_process_count }}</el-tag
            >
          </Menu-item>
          <!-- <Menu-item name="/qna/professor">
            <span class="qna board">강사 전용</span>
          </Menu-item>
          <Menu-item name="/qna/request">
            <span class="qna board">버그/요청 전용</span>
          </Menu-item> -->
        </Submenu>
        <!-- 본인 user profile에서 가져온거 바로 넣기 profile의 mincoding_auth에 있는 group가져오기    -->
        <!-- <Menu-item v-else-if="isValidQna()" :name="`/qna/${router_name}`">
          <span class="qna board" @click="getAdminLists">질문게시판</span>
        </Menu-item> -->
        <Menu-item
        v-else-if="isValidQna()"
        :name="`/qna/${makeRoute(
          $store.state.user.profile.mincoding_auth.router_name
        )}`"
      >
        <span class="qna board" @mouseenter="getAdminLists">질문게시판</span>
      </Menu-item>


        <Submenu name="notice-list" v-if="isSuperAdmin" ref="notice-list">
          <template slot="title">
            <span class="menu-title board" @click="getAdminLists"
              >응시가이드</span
            >
          </template>
          <Menu-item
            v-for="notice in noticeList"
            :key="notice.id"
            :name="`/announcement/${makeRoute(notice.router_name)}`"
          >
            <span class="notice-list board">{{ notice.group }} 전용</span>
          </Menu-item>
          <!-- <Menu-item name="/announcement/mincoding">
            <span class="notice-list board">민코딩 전용</span>
          </Menu-item> -->
        </Submenu>
        <!-- 본인 user profile에서 가져온거 바로 넣기 profile의 mincoding_auth에 있는 group가져오기    -->
        <!-- <Menu-item
          v-else-if="isValidAnnounce()"
          :name="`/announcement/${router_name}`"
        >
          <span class="notice board" @click="getAdminLists">응시가이드</span>
        </Menu-item> -->

        <Menu-item
        v-else-if="isValidAnnounce()"
        :name="`/announcement/${makeRoute(
          $store.state.user.profile.mincoding_auth.router_name
        )}`"
      >
        <span class="notice board" @click="getAdminLists">응시가이드</span>
      </Menu-item>

        <!-- 23/02/01 변성은 추가 start -->
        <Submenu
          name="e_learnings"
          v-if="isSuperAdmin || isValidELearning()"
          ref="e_learnings"
          :class="{
            'ivu-menu-item-active ivu-menu-child-item-active': isActiveSubMenu(
              '/e_learnings',
              activeSubMenu
            ),
          }"
        >
          <template slot="title">
            <span class="e_learnings board" @click="getAdminLists"
              >e-learning</span
            >
          </template>
          <Menu-item name="/e_learnings/level/miracom">
            <span class="e_learnings-level board">Level별 강의</span>
          </Menu-item>
          <Menu-item name="/e_learnings/pro-new/miracom">
          <span class="e_learnings-pro board">Pro 원정대(신 유형)</span>
        </Menu-item>
          <Menu-item name="/e_learnings/pro/miracom">
          <span class="e_learnings-pro board">Pro 원정대(기존유형)</span>
        </Menu-item>
        <Menu-item name="/e_learnings/pro-learning/miracom">
          <span class="e_learnings-pro board">미라콤 러닝 트랙</span>
        </Menu-item>
        </Submenu>
        <!-- 23/01/30 변성은 추가 end -->

        <Menu-item
          name="/test-review-board-miracom"
          v-if="isSuperAdmin "
        >
          <span class="menu-title">시험후기</span>
        </Menu-item>

      

        <Submenu name="about" class="about">
          <template slot="title">
            <span class="menu-title">{{ $t("m.About") }}</span>
          </template>
          <Menu-item name="/about" style="padding-left: 20px">
            {{ $t("m.Judger") }}
          </Menu-item>
          <Menu-item name="/FAQ" style="padding-left: 20px">
            {{ $t("m.FAQ") }}
          </Menu-item>
          <Menu-item name="/Java" style="padding-left: 20px">
            Java 입출력방법
          </Menu-item>
        </Submenu>
        <!-- <li class="online-button" @click="making" style="">
          <div  v-if="isSuperAdmin || isValidMiracom || isValidSDS" >
            <span  class="menu-title">온라인강의</span>
          </div>
        </li> -->
      </Menu>
      <Modal v-model="modalVisible" :width="400">
        <div slot="header" class="modal-title">
          {{ $t("m.Welcome_to") }} {{ website.website_name_shortcut }}
        </div>
        <component :is="modalStatus.mode" v-if="modalVisible"></component>
        <div slot="footer" style="display: none"></div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import login from "@oj/views/user/Login";
import register from "@oj/views/user/Register";
import api from "../api";
import adminApi from "../../admin/api";
import mixins from "./mixins/navBarMixins";
export default {
  components: {
    login,
    register,
  },
  mixins: [mixins],
  async mounted() {
    this.getProfile();
    const { data } = await api.getProblemStepList();

    this.problemStepList = data["data"]["data"];
    this.getAdminLists();
  },
  computed: {
    ...mapGetters([
      "website",
      "modalStatus",
      "user",
      "isAuthenticated",
      "isAdminRole",
      "isSuperAdmin",
      "userinfo",

    ]),
    ...mapState(["user"]),
  },
  data() {
    return {
      vcoConfig: {
        middleware: this.middleware,
        events: ["click"],
      },
      qnaList: [],
      noticeList: [],
      mobileFlag: true,
      currentVisible: false,
      problemStepList: [],
    };
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    showGnb() {
      this.mobileFlag
        ? (this.$refs.header.style.transform = "translateX(0)")
        : (this.$refs.header.style.transform = "translateX(-100%)");
      this.mobileFlag = !this.mobileFlag;
    },
    middleware() {
      if (!this.mobileFlag) {
        this.mobileFlag = !this.mobileFlag;
      }
      this.$refs.header.style.transform = "translateX(-100%)";
    },
    moveMain() {
      this.$router.push("/");
    },
  },
  watch: {
    $route() {
      this.middleware();
    },
  },
};
</script>

<style lang="less" scoped>
#moblie-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 10;
  background-color: #fff;
  min-height: 60px;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
  .logoImage {
    width: 70px;
    height: 25px;
    margin: 0px auto;
  }
  .el-icon-s-unfold {
    font-size: 25px;
    margin-left: 15px;
  }
  .gnb {
    font-size: 25px;
    cursor: pointer;
  }
  .drop-menu {
    &-title {
      font-size: 16px;
    }
  }
}
#header {
  overflow: scroll;
  position: absolute;
  top: 60px;
  height: 100%;
  z-index: 20;
  background-color: #fff;
  transform: translateX(-100%);
  transition: 1s;
  .el-icon-close {
    font-size: 25px;
    min-height: 60px;
    line-height: 60px;
  }
  .oj-menu {
    background: #fdfdfd;
    font-family: "Noto Sans KR", sans-serif;
    .menu-title {
      font-size: 16px;
      letter-spacing: -1px;
      margin-left: -7px;
      margin-right: 5px;
    }
    .board {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.modal {
  &-title {
    font-size: 18px;
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  .btn-menu {
    display: flex;
  }
  #header {
    position: fixed !important;
  }
}
</style>
