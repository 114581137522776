<template>
  <div>
	
    <div style="display: flex; position: relative;">
      <!-- <el-input
		  v-model="keyword"
		  placeholder="키워드를 입력하세요"
		  prefix-icon="el-icon-search">
		</el-input> -->
		
	<div class="write-button" style="margin-top:10px;">
		<el-button :size="$store.state.isMobile ?'mini':'medium'"  type="success" 
        @click.native="handleSave()"
        class="write-btn"
        
          >{{type ==='search'? '검색할 태그 목록 담기' : '문제에 태그 적용하기' }}</el-button
        >

		<el-button v-if="!type =='search'" :size="$store.state.isMobile ?'mini':'medium'" type="primary" 
        @click.native="handleInlineCreate()"
        class="write-btn"
          >태그 새로 만들기
    </el-button>
	</div>
		
		
	
      <el-input
        style="margin-left: 20px"
        v-model="keyword"
        placeholder="검색할 태그를 입력하세요"
        prefix-icon="el-icon-search"
      >
      </el-input>
	  
    </div>
    <div class="tag-list-wrapper">
      <el-tag
	  	style="margin:3px"
        v-for="tag in $store.state.problem.tagList"
        :key="tag.id"
		closable
        @click.native="handleClose(tag)"
		@close="handleClose(tag)"
        :type="tag.type"
      >
        {{ tag.name }}
      </el-tag>
    </div>
    <el-table
      empty-text="검색한 정보가 없습니다"
      :data="tagList"
      v-loading="loading"
	  @row-click="clickRow"
    >
      <el-table-column
        v-if="!$store.state.isMobile"
        label="ID"
        width="100"
        prop="id"
      >
      </el-table-column>

      <el-table-column min-width="150" label="이름" prop="name">
		<template slot-scope="{ row }" >
		<div >
			{{ row.name }}
		</div>
		</template>
      </el-table-column>

      <el-table-column label="옵션" align="center" width="100">
        <template slot-scope="{ row }">
          <icon-btn
            icon="plus"
            name="태그 추가"
            @click.native="handleTag(row)"
          ></icon-btn>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="page"
      layout="prev, pager, next"
      @current-change="getTagList"
      :page-size="limit"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      title="태그 추가"
      width="30%"
      :visible.sync="InlineEditDialogVisible"
      @close-on-click-modal="false"
    >
      <div>
        <p>
          name:
          <el-input
            v-model="changeName"
            
          ></el-input>
        </p>
		<div style="color:red; text-align: center;">
			{{ errorMessage }}
		</div>
      </div>
      <span slot="footer">
        <cancel
          @click.native="
            InlineEditDialogVisible = false;
            getTagList();
          "
        ></cancel>
        
        <save @click.native="createTag()"></save>
      </span>
    </el-dialog>


  </div>
</template>
  <script>
import { types } from "@/store";
import api from "@admin/api";

export default {
  name: "add-problem-from-public",
  props: ["contestID", "problemId", "type"],
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      tagList: [],
      contest: {},
      keyword: "",
      tag: "",
      pageSize: 10,
	  InlineEditDialogVisible: false,
	  changeName:"",
	  errorMessage:""
    };
  },
  mounted() {
    // problemId가 존재하면
    // 저장시에 problem에 해당 태그들 리스트 가져오기

    // 해당 tag 선택하기
    this.getTagList();
    // api.getContest(this.contestID).then(res => {
    //   this.contest = res.data.data
    //   this.getPublicProblem()
    // }).catch(() => {
    // })
  },

  methods: {
    getTagList(page = 1) {
      let params = {
        offset: (page - 1) * this.pageSize,
        limit: this.pageSize,
        keyword: this.keyword,
      };

      api.getTagList(params).then((li) => {
        this.loading = false;
        
        this.tagList = li.data.data.results;
        this.total = li.data.data.total;
      });
    },

    getPublicProblem(page) {
      this.loading = true;
      let params = {
        keyword: this.keyword,
        tag: this.tag,
        offset: (page - 1) * this.limit,
        limit: this.limit,
        rule_type: this.contest.rule_type,
      };
      api
        .getProblemList(params)
        .then((res) => {
          this.loading = false;
          this.total = res.data.data.total;
          this.problems = res.data.data.results;
        })
        .catch(() => {});
    },
    handleTag(row) {
      this.$store.commit(types.ADD_TAG, row);
    },
    handleAddProblem(problemID) {
      this.$prompt("시험응시 문제에 대한 표시 ID를 입력하십시오", "확인").then(
        ({ value }) => {
          let data = {
            problem_id: problemID,
            contest_id: this.contestID,
            display_id: value,
          };
          api.addProblemFromPublic(data).then(
            () => {
              this.$emit("on-change");
            },
            () => {}
          );
        },
        () => {}
      );
    },
	handleClose(tag){
		
		this.$store.commit(types.REMOVE_TAG, tag);
	},
	clickRow(row,column, event){
		this.$store.commit(types.ADD_TAG, row);
	},

	createTag(){
      if(!this.changeName){
      this.InlineEditDialogVisible = false;
      return;

      }

      const data ={
        name: this.changeName.trim()
      }

	  const pattern = /\s/;

	  if(pattern.test(data.name)){
        
        this.errorMessage = "태그에 공백을 넣을수 없습니다.";
        return;
      }

      api.createTag(data).then(li => {
        this.getTagList();
        this.InlineEditDialogVisible = false;
		this.keyword="";
		this.errorMessage = "";
      }).catch(error=>{
		
		this.errorMessage = error.data.data;
	  })

    },
	handleInlineCreate(){  
      this.changeName = "";
	  this.errorMessage = "";
      this.InlineEditDialogVisible = true;
      
    },
	handleSave(){
		this.$emit("saveTag")
	}

	
  },
  watch: {
    keyword() {
      this.getTagList(this.page);
    },
  },
};
</script>
  <style scoped>
.page {
  margin-top: 20px;
  text-align: right;
}

</style>
<style>
.tag-list-wrapper{
	justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.write-button{
	position: absolute;
	right: 0;
	top:-50px;
	z-index: 50;
}

</style>
  