<template>
  <div class="announcement view">
    <AdminPanel :title="isNews ? 'NEWS' : '강사님 코드'">
      <div class="list">
        <div style="text-align: right; margin: 10px auto">
          <el-button
            type="primary"
            size="small"
            @click="openAnnouncementDialog(null)"
            icon="el-icon-plus"
            >{{ isNews ? "NEWS생성" : "강사님 코드 생성" }}</el-button
          >
        </div>
        <el-table
          empty-text="검색한 정보가 없습니다"
          v-loading="loading"
          element-loading-text="loading"
          ref="table"
          :data="announcementList"
          style="width: 100%"
        >
          <el-table-column
            v-if="!$store.state.isMobile"
            width="100"
            prop="id"
            label="ID"
          >
          </el-table-column>
          <el-table-column prop="title" min-width="200" label="제목">
          </el-table-column>
          <el-table-column
            v-if="!$store.state.isMobile"
            prop="create_time"
            label="생성 날짜"
          >
            <template slot-scope="scope">
              {{ scope.row.create_time | localtime }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="!$store.state.isMobile"
            prop="last_update_time"
            label="최근 수정 시간"
          >
            <template slot-scope="scope">
              {{ scope.row.last_update_time | localtime }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="!$store.state.isMobile"
            prop="created_by.username"
            label="작성자"
          >
          </el-table-column>
          <el-table-column width="100" prop="visible" label="공개 여부">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.visible"
                active-text=""
                inactive-text=""
                @change="handleVisibleSwitch(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="옵션" width="200">
            <div slot-scope="scope">
              <icon-btn
                name="수정"
                icon="edit"
                @click.native="openAnnouncementDialog(scope.row.id)"
              ></icon-btn>
              <icon-btn
                name="삭제"
                icon="delete"
                @click.native="deleteAnnouncement(scope.row.id)"
              ></icon-btn>
            </div>
          </el-table-column>
        </el-table>
        <div class="panel-options">
          <el-button
            type="primary"
            size="small"
            @click="openAnnouncementDialog(null)"
            icon="el-icon-plus"
            >{{ isNews ? "NEWS생성" : "강사님 코드 생성" }}</el-button
          >
          <el-pagination
            v-if="!contestID"
            class="page"
            layout="prev, pager, next"
            @current-change="currentChange"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </AdminPanel>
    <!-- 강사님 코드 수정 다이얼로그-->
    <el-dialog
      :class="$store.state.isMobile ? 'mobile-dialog' : ''"
      :width="$store.state.isMobile ? '100vw' : '70%'"
      top="50px"
      :title="announcementDialogTitle"
      :visible.sync="showEditAnnouncementDialog"
      @open="onOpenEditDialog"
      :close-on-click-modal="false"
    >
      <el-form label-position="top">
        <el-form-item :label="'제목'" for="제목" required>
          <el-input
            id="제목"
            v-model="announcement.title"
            :placeholder="'제목'"
            class="title-input"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="'내용'" required>
          <VueCkEditor
            :data="announcement.content"
            @setContent="setContent"
          ></VueCkEditor>
          <!-- <Simditor v-model="announcement.content"></Simditor> -->
        </el-form-item>
        <div class="visible-box">
          <!--
          <span>상태</span>
          -->
          <el-switch
            v-model="announcement.visible"
            active-text="공개"
            inactive-text="비공개"
          >
          </el-switch>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <cancel @click.native="showEditAnnouncementDialog = false"></cancel>
        <save type="primary" @click.native="submitAnnouncement"></save>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Simditor from "../../components/Simditor.vue";
import api from "../../api.js";
import AdminPanel from "@admin/components/AdminPanel.vue";
import VueCkEditor from "../../../admin/components/VueCkEditor.vue";
export default {
  name: "Announcement",
  components: {
    Simditor,
    AdminPanel,
    VueCkEditor,
  },
  data() {
    return {
      contestID: "",
      // 다이얼로그 표시 여부
      showEditAnnouncementDialog: false,
      // announcement 목록
      announcementList: [],
      // 한 페이지에 표시되는 announcement 수
      pageSize: 15,
      // 총 announcement 수
      total: 0,
      // 현재 announcement ID
      currentAnnouncementId: null,
      mode: "create",
      // announcement (신규 | 편집) 모델
      announcement: {
        title: "",
        visible: true,
        content: "",
      },
      // announcement 제목
      announcementDialogTitle: "",
      // 표시여부loading
      loading: true,
      // 현재 페이지 번호
      currentPage: 0,
      //210604 news와 강사님코드 구분
      isNews: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.contestID = this.$route.params.contestId;
      if (this.contestID) {
        this.getContestAnnouncementList();
        this.isNews = false;
      } else {
        this.getAnnouncementList(1);
        this.isNews = true;
      }
    },
    currentChange(page) {
      this.currentPage = page;
      this.getAnnouncementList(page);
    },
    getAnnouncementList(page) {
      this.loading = true;
      api.getAnnouncementList((page - 1) * this.pageSize, this.pageSize).then(
        (res) => {
          this.loading = false;
          this.total = res.data.data.total;
          this.announcementList = res.data.data.results;
        },
        (res) => {
          this.loading = false;
        }
      );
    },
    getContestAnnouncementList() {
      this.loading = true;
      api
        .getContestAnnouncementList(this.contestID)
        .then((res) => {
          this.loading = false;
          this.announcementList = res.data.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 편집 대화 상자를 열기위한 콜백
    onOpenEditDialog() {
      // 할일 최적화
      // 일시적으로 텍스트 편집기 디스플레이 비정상 버그 해결g
      setTimeout(() => {
        if (document.createEvent) {
          let event = document.createEvent("HTMLEvents");
          event.initEvent("resize", true, true);
          window.dispatchEvent(event);
        } else if (document.createEventObject) {
          window.fireEvent("onresize");
        }
      }, 0);
    },
    // 수정 제출
    // MouseEvent는 기본적으로 전달됩니다.
    submitAnnouncement(data = undefined) {
      let funcName = "";
      if (!data.title) {
        data = {
          id: this.currentAnnouncementId,
          title: this.announcement.title,
          content: this.announcement.content,
          visible: this.announcement.visible,
        };
      }
      if (this.contestID) {
        data.contest_id = this.contestID;
        funcName =
          this.mode === "edit"
            ? "updateContestAnnouncement"
            : "createContestAnnouncement";
      } else {
        funcName =
          this.mode === "edit" ? "updateAnnouncement" : "createAnnouncement";
      }
      api[funcName](data)
        .then((res) => {
          this.showEditAnnouncementDialog = false;
          this.init();
        })
        .catch();
    },
    // Announcement 삭제
    deleteAnnouncement(announcementId) {
      this.$confirm("정말로 삭제하시겠습니까?", "경고", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        type: "warning",
      })
        .then(() => {
          // then 확실히
          this.loading = true;
          let funcName = this.contestID
            ? "deleteContestAnnouncement"
            : "deleteAnnouncement";
          api[funcName](announcementId).then((res) => {
            this.loading = true;
            this.init();
          });
        })
        .catch(() => {
          // catch 취소
          this.loading = false;
        });
    },
    openAnnouncementDialog(id) {
      this.showEditAnnouncementDialog = true;
      if (id !== null) {
        this.currentAnnouncementId = id;
        this.announcementDialogTitle = this.isNews
          ? "NEWS 수정"
          : "강사님 코드 수정";
        this.announcementList.find((item) => {
          if (item.id === this.currentAnnouncementId) {
            this.announcement.title = item.title;
            this.announcement.visible = item.visible;
            this.announcement.content = item.content;
            this.mode = "edit";
          }
        });
      } else {
        this.announcementDialogTitle = this.isNews ? "NEWS" : "강사님 코드";
        this.announcement.title = "";
        this.announcement.visible = true;
        this.announcement.content = "";
        this.mode = "create";
      }
    },
    handleVisibleSwitch(row) {
      this.mode = "edit";
      this.submitAnnouncement({
        id: row.id,
        title: row.title,
        content: row.content,
        visible: row.visible,
      });
    },
    setContent(e) {
      this.announcement.content = e;
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>

<style lang="less">
.title-input {
  margin-bottom: 20px;
}

.visible-box {
  margin-top: 10px;
  width: 205px;
  float: left;
}
.mobile-dialog > .el-dialog {
  position: absolute !important;
  left: 5px !important;
}
</style>
