import { render, staticRenderFns } from "./MiracomTestReviewReadMain.vue?vue&type=template&id=abc45c98&"
import script from "./MiracomTestReviewReadMain.vue?vue&type=script&lang=js&"
export * from "./MiracomTestReviewReadMain.vue?vue&type=script&lang=js&"
import style0 from "./MiracomTestReviewReadMain.vue?vue&type=style&index=0&id=abc45c98&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports