<template>
  <Row type="flex" :gutter="18">
    <Col :span="24">
      <Panel shadow>
        <div slot="title">문항 목록</div>
        <Table
          border
          style="width: 100%; font-size: 16px"
          :columns="problemTableColumns"
          :data="problemList"
          :loading="loadings.table"
        ></Table>
      </Panel>
    </Col>
  </Row>
  <!--
  @on-row-click="move"
  -->
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import api from "@oj/api";
import utils from "@/utils/utils";

export default {
  name: "Favorite",
  data() {
    return {
      tagList: [],
      problemTableColumns: [
        {
          title: "해결",
          key: "_id",
          width: 50,
          align: "center",
          render: (h, params) => {
            let solveAcmProblem;
            let solveOiProblem;
            let status = null;

            if (this.$store.state.user.profile.acm_problems_status.problems) {
              solveAcmProblem =
                this.$store.state.user.profile.acm_problems_status.problems[
                  params.row.problem.id
                ];
            }

            if (this.$store.state.user.profile.oi_problems_status.problems) {
              solveOiProblem =
                this.$store.state.user.profile.oi_problems_status.problems[
                  [params.row.problem.id]
                ];
            }

            if (solveAcmProblem) {
              status = solveAcmProblem.status;
            }
            if (solveOiProblem) {
              status = solveOiProblem.status;
            }

            if (status === null || status === undefined) {
              return undefined;
            }
            return h("Icon", {
              props: {
                type: status === 0 ? "checkmark-round" : "minus-round",
                size: "16",
              },
              style: {
                color: status === 0 ? "#19be6b" : "#ed3f14",
              },
            });
          },
        },
        {
          title: "문제 번호",
          key: "_id",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "text",
                  size: "small",
                },
              },
              `${params.row._index + 1} 번`
            );
          },
        },
        {
          title: this.$i18n.t("m.Title"),
          className: "iview-table-title",
          align: "left",
          width: 400,
          style: {
            width: "600px",
          },
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "text",
                  size: "large",
                },
                on: {
                  click: () => {
                    this.move(params.row);
                  },
                },
                style: {
                  "padding-left": "30px",
                },
              },
              params.row.problem.title
            );
          },
        },
        {
          title: this.$i18n.t("m.Level"),
          width: 150,
          align: "center",
          render: (h, params) => {
            let t = params.row.difficulty;

            let color = "blue";
            if (t === 1) color = "green";
            else if (t === 3) color = "yellow";

            let text = "Mid";

            if (t === 1) text = "Low";
            if (t === 3) text = "High";

            return h(
              "tag",
              {
                props: {
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "TC",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("span", `${params.row.problem.test_case_score.length} 개`);
          },
        },
        {
          title: "해설",
          key: "submission_number",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h(
              "span",
              params.row.problem.teacher_code_visible ? "✔️" : "❌"
            );
          },
        },
        {
          title: "즐겨찾기",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.removeFavorite(params);
                    },
                  },
                },
                "삭제"
              ),
            ]);
          },
        },
      ],
      problemList: [],
      limit: 20,
      total: 0,
      loadings: {
        table: true,
        tag: true,
      },
      routeName: "",
      query: {
        keyword: "",
        difficulty: "",
        tag: "",
        page: 1,
      },
    };
  },
  mounted() {
    this.init();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile"]),

    init(simulate = false) {
      this.routeName = this.$route.name;
      let query = this.$route.query;
      this.query.difficulty = query.difficulty || "";
      this.query.keyword = query.keyword || "";
      this.query.tag = query.tag || "";
      this.query.page = parseInt(query.page) || 1;
      if (this.query.page < 1) {
        this.query.page = 1;
      }
      if (!simulate) {
        this.getTagList();
      }
      this.getProblemList();
    },
    pushRouter() {
      this.$router.push({
        name: "favorite-list",
        query: utils.filterEmptyValue(this.query),
      });
    },
    async getProblemList() {
      this.loadings.table = true;
      const { data } = await api.getFavorite(this.$store.state.user.profile.id);
      this.problemList = data["data"];
      this.loadings.table = false;
    },
    getTagList() {
      api.getProblemTagList().then(
        (res) => {
          this.tagList = res.data.data;
          this.loadings.tag = false;
        },
        (res) => {
          this.loadings.tag = false;
        }
      );
    },
    filterByTag(tagName) {
      this.query.tag = tagName;
      this.query.page = 1;
      this.pushRouter();
    },
    filterByDifficulty(difficulty) {
      this.query.difficulty = difficulty;
      this.query.page = 1;
      this.pushRouter();
    },
    filterByKeyword() {
      this.query.page = 1;
      this.pushRouter();
    },
    handleTagsVisible(value) {
      if (value) {
        this.problemTableColumns.push({
          title: this.$i18n.t("m.Tags"),
          align: "center",
          render: (h, params) => {
            let tags = [];
            params.row.tags.forEach((tag) => {
              tags.push(h("Tag", {}, tag));
            });
            return h(
              "div",
              {
                style: {
                  margin: "8px 0",
                },
              },
              tags
            );
          },
        });
      } else {
        this.problemTableColumns.splice(this.problemTableColumns.length - 1, 1);
      }
    },
    onReset() {
      this.$router.push({ name: "problem-list" });
    },
    levelEdit() {
      const { level_id, step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}/level/${level_id}/edit`);
    },
    detailListEdit() {
      const { level_id, step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}/level/${level_id}/detail`);
    },
    move(row, index) {
      this.$router.push(`/problem/${row.problem._id}`);
    },
    removeFavorite(params) {
      this.$Modal.confirm({
        content: "즐겨찾기를 삭제하시겠습니까?",
        onOk: async () => {
          const problem_id = params.row.problem.id;
          const user_id = this.$store.state["user"]["profile"].id;
          const data = { user_id, problem_id };
          const { result } = await api.deleteFavorite(data);
          this.getProblemList();
        },
        onCancel: () => {},
      });
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isAdminRole"]),
    ...mapState(["utils"]),
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(true);
      }
    },
    isAuthenticated(newVal) {
      if (newVal === true) {
        this.init();
      }
    },
  },
};
</script>

<style lang="less">
.iview-table-title .ivu-table-cell {
  padding: 0;
}
</style>
