import { mapGetters, mapActions, mapState } from "vuex";
import login from "@oj/views/user/Login";
import register from "@oj/views/user/Register";
import api from "../../api";
import adminApi from "../../../admin/api";

export default {
  components: {
    login,
    register,
  },
  async created() {
    // 이떄 secrectCodeList를 호출할수있는 권한 을 호출한다
    // if(this.isSuperAdmin){
    this.getAdminLists();

    if(this.$store.state.user && this.$store.state.user?.profile?.mincoding_auth?.router_name){
      this.router_name = this.$store.state.user?.profile?.mincoding_auth?.router_name?.toLowerCase()
    }
    this.getProfile();
    // api로 list호출하기
    const { data } = await api.getProblemStepList();
    this.problemStepList = data["data"]["data"];

    // }
  },
  async mounted() {
   
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      currentVisible: false,
      problemStepList: [],
      contestList:[],
      qnaList: [],
      noticeList: [],
      e_learningList:[],
      router_name: ''
    };
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    cancel(e){
      
    },
    handleRoute(route) {
      if (this.currentVisible === true) {
        this.currentVisible = false;
      }
      if (route === "/typing") {
        if (window.location.origin.indexOf("mir") !== -1) {
          window.open("http://mir.mincoding.co.kr/typing");
        } else {
          window.open("https://pro.mincoding.co.kr/typing");
        }
        this.$router.go();
      }
      if (route && route.indexOf("admin") < 0) {
        this.$router.push(route).catch((e) => {
          // console.log("권한이없습니다")
        });
      } else {
        window.open("/admin/");
      }
    },
    handleBtnClick(mode) {
      this.changeModalStatus({
        visible: true,
        mode: mode,
      });
    },
    isLoggedIn() {
      return !!(
        this.$store.state["user"]["profile"] &&
        this.$store.state["user"]["profile"].id
      );
    },
    isAuth() {
      return this.problemStepList.length > 0;
    },
    isOutclick() {
      if (this.currentVisible === true) {
        this.currentVisible = false;
      }
    },
    isVisible() {
      this.currentVisible = !this.currentVisible;
    },
    closeDropdown() {
     
      this.getAdminLists()
      if (this.currentVisible === true) {
        this.currentVisible = false;
      }
    },
    async setProblemStepList(){
      const { data } = await api.getProblemStepList();
      this.problemStepList = data["data"]["data"];
      
    },
    getBoardsInfo(){
      this.getAdminLists();

    },
    async isOpen(navigation) {
      const { data } = await api.getProblemStepList();
      this.problemStepList = data["data"]["data"];
      if (navigation === "problemList") {
        this.$refs.problemList.opened = !this.$refs.problemList.opened;
      }
      if (navigation === "contest") {
        this.$refs.contest.opened = !this.$refs.contest.opened;
      }
      if (navigation === "board") {
        this.$refs.board.opened = !this.$refs.board.opened;
      }
      if (navigation === "notice") {
        this.$refs.notice.opened = !this.$refs.notice.opened;
      }
      if (navigation === "about") {
        this.$refs.about.opened = !this.$refs.about.opened;
      }
    },

    async getAdminLists() {

      const listResult = api.getProblemStepList().then(li=>{
        this.problemStepList = li.data["data"]["data"];
      });
      

      // 관리자일때랑 아닐때 분기하기
      if (this.isSuperAdmin) {
        const { data } =  await adminApi.getSecretCodeAuth();
        
        this.qnaList = data.data.filter((li) => li.is_qna_visible);
        this.contestList = data.data.filter((li) => li.is_contest_visible);
        // this.qnaList = await this.reduceRoop()

        this.noticeList = data.data.filter((li) => li.is_notice_visible);
      } else if (
        this.$store.state.user.profile &&
        this.$store.state.user.profile.mincoding_auth
      ) {
        //  console.log(this.$store.state.user.profile && this.$store.state.user.profile.mincoding_auth)
        // console.log(this.$store.state.user.profile.mincoding_auth);
        // console.log(this.$store.state.user.profile.mincoding_auth.group);
      }
    },
    isValidContest(){
      const { user } = this.$store.state;
      if (!user.profile) return false;
      if (!user.profile.mincoding_auth) return false;
      if (!user.profile.mincoding_auth.router_name) return false;
      if (!user.profile.mincoding_auth.is_contest_visible) return false;
      const isValidEmail = this.isValidEmail(user);
      const isValidUserName = this.isValidUsername(user);
      if(isValidEmail === true && isValidUserName === true) return true;
      return false;
    },
    isValidQna() {
      const { user } = this.$store.state;
      if (!user.profile) return false;
      if (!user.profile.mincoding_auth) return false;
      if (!user.profile.mincoding_auth.router_name) return false;
      if (!user.profile.mincoding_auth.is_qna_visible) return false;
      // 두가지 모두 공백값이면 넘긴다

      // verify_email 문자열이 0보다 클떄
      // 이메일 검증 
      const isValidEmail = this.isValidEmail(user);
      const isValidUserName = this.isValidUsername(user);
      if(isValidEmail === true && isValidUserName === true) return true;
      return false;

    },

    isValidELearning() {
      const { user } = this.$store.state;
      
      if (!user.profile) return false;
      if (!user.profile.mincoding_auth) return false;
      if (!user.profile.mincoding_auth.router_name) return false;
      if (!user.profile.mincoding_auth.is_e_learning_visible) return false;

      // 두가지 모두 공백값이면 넘긴다

      // verify_email 문자열이 0보다 클떄
      // 이메일 검증 
      const isValidEmail = this.isValidEmail(user);
      const isValidUserName = this.isValidUsername(user);
      if(isValidEmail === true && isValidUserName === true) return true;
      return false;

    },

    isValidAnnounce(){
       const { user } = this.$store.state;
      if (!user.profile) return false;
      if (!user.profile.mincoding_auth) return false;
      if (!user.profile.mincoding_auth.router_name) return false;
      if (!user.profile.mincoding_auth.is_notice_visible) return false;
      const isValidEmail = this.isValidEmail(user);
      const isValidUserName = this.isValidUsername(user);
      if(isValidEmail === true && isValidUserName === true) return true;
      return false;
    },
    isValidEmail(user) {
      let flag = false;
      // verify_email의
      // email이 존재하면서 length가 0보다 클떄
      // 즉 문자열이 존재하면서 조건을 만족해야한다
      if (
        user.profile.mincoding_auth.verify_email &&
        user.profile.mincoding_auth.verify_email.length > 0
      ) {
        
        const zigbangEmailFLag = user.profile.user.email.indexOf("@zigbang.com");
        const zigbangGithubFlag =user.profile.github.toLowerCase() === "sds";
        if (
          zigbangEmailFLag  >
          -1 && zigbangGithubFlag
        ) {
          flag = true;
        }
        
        // if(user.profile.)
        if (
          user.profile.user.email.indexOf(
            user.profile.mincoding_auth.verify_email.trim()
          ) > -1
        ) {
          flag = true;
        }
      }

      if (!user.profile.mincoding_auth.verify_email) flag = true;
      return flag;
    },
  isValidUsername(user) {
      let flag = false;
      // console.log(user.profile.user);
      if(user.profile.mincoding_auth.verify_username && user.profile.mincoding_auth.verify_username.length >0){
        if(user.profile.user.username.slice(0,8) === user.profile.mincoding_auth.verify_username.trim()){
          flag = true;
        }
      }
      // console.log(user.profile.user.username.slice(0,8));
      // console.log(user.profile.mincoding_auth.verify_username)
      if (!user.profile.mincoding_auth.verify_username) flag = true;
      // console.log(flag);
      return flag
    },
    makeRoute(router_name){
      if(router_name){
        return router_name.toLowerCase()
      }else{
        return ''
      }
    },
    isActiveSubMenu(menu, router_name){
      if(router_name === menu) return true;
      else if(menu === '/problem-step' && router_name === '/Favorite') return true;
      else return false;
    },
  },
  computed: {
    ...mapGetters([
      "website",
      "modalStatus",
      "user",
      "isAuthenticated",
      "isAdminRole",
      "isSuperAdmin",
      "userinfo"
    ]),

    activeMenu() {
      // return "/" + this.$route.path.split("/")[1];
      if(this.$route.path.split("/")[1] == "problem-step"){
        return "/" + this.$route.path.split("/")[1] + "/" + this.$route.path.split("/")[2] + "/level"
      } else if(this.$route.path.split("/")[1] == "enterprise"){
        return "/" + this.$route.path.split("/")[1] + "/" + this.$route.path.split("/")[2] + "/" + this.$route.path.split("/")[3]
      } else if(this.$route.path.split("/")[1] == "qna"){
        return "/" + this.$route.path.split("/")[1] + "/" + this.$route.path.split("/")[2]
      } else if(this.$route.path.split("/")[1] == "announcement"){
        return "/" + this.$route.path.split("/")[1] + "/" + this.$route.path.split("/")[2]
      } 
      return this.$route.path;
    },
    modalVisible: {
      get() {
        return this.modalStatus.visible;
      },
      set(value) {
        this.changeModalStatus({ visible: value });
      },
    },

    activeSubMenu(){
      if(this.$route.path.split("/")[1] == "enterprise"){
        return "/" + this.$route.path.split("/")[1] + "/" + this.$route.path.split("/")[2]
      }
      return "/" + this.$route.path.split("/")[1]
    },
  },
};